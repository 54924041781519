const config = {
  title: 'Layout 1 - Vertical',
  defaults: {
    mode: 'container',
    containerWidth: 1570,
    navbar: {
      display: true,
      style: 'style-1',
      folded: true,
      position: 'left',
    },
    toolbar: {
      display: true,
      style: 'fixed',
    },
  },
  form: {
    mode: {
      title: 'Mode',
      type: 'radio',
      options: [
        {
          name: 'Boxed',
          value: 'boxed',
        },
        {
          name: 'Full Width',
          value: 'fullwidth',
        },
        {
          name: 'Container',
          value: 'container',
        },
      ],
    },
    containerWidth: {
      title: 'Container Width (px)',
      type: 'number',
    },

  },
};

export default config;
