import { ThemeProvider } from '@mui/material/styles';
import { memo } from 'react';

function FuseTheme(props) {
  const { theme } = props;
;
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}

export default memo(FuseTheme);
