/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import history from '@history';
import _ from '@lodash';
import { setInitialSettings } from 'app/store/fuse/settingsSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import settingsConfig from 'app/configs/settingsConfig';
import jwtService from '../auth/services/jwtService';
import FuseUtils from '@fuse/utils';

export const setUser = createAsyncThunk('user/setUser', async (user, { dispatch, getState }) => {
  /*
    You can redirect the logged-in user to a specific route depending on his role
    */
  // if (user.loginRedirectUrl) {
  //   settingsConfig.loginRedirectUrl = user.loginRedirectUrl; // for example '/apps/academy'
  // }

  const subAdminHasPermission = FuseUtils.hasPermission(user.permissions, ["subAdminDashboard"]);
  const designerHasPermission = FuseUtils.hasPermission(user.permissions, ["designerDashboard"]);
  const seoHasPermission = FuseUtils.hasPermission(user.permissions, ["seoDashboard"]);
  const adminHasPermission = FuseUtils.hasPermission(user.permissions, ["adminDashboard"]);


  console.log("adminHasPermission", adminHasPermission)
  console.log("subAdminHasPermission", subAdminHasPermission)
  console.log("designerHasPermission", designerHasPermission)
  console.log("seoHasPermission", seoHasPermission)



  if(adminHasPermission){
    settingsConfig.loginRedirectUrl = "dashboard/admin"; // for example '/apps/academy'
    // history.push({
    //   pathname: '/dashboards/analytics',
    // });
  }
  if(subAdminHasPermission){
    settingsConfig.loginRedirectUrl = "dashboard/subadmin"; // for example '/apps/academy'
  }
  if(designerHasPermission){
    settingsConfig.loginRedirectUrl = "dashboard/designer"; // for example '/apps/academy'
  }
  if(seoHasPermission){
    settingsConfig.loginRedirectUrl = "dashboard/seo"; // for example '/apps/academy'
  }
  

  // if(user.permissions.includes("adminDashboard")){
  //   console.log("user8", user)

  //   }


  return user;
});

export const updateUserSettings = createAsyncThunk(
  'user/updateSettings',
  async (settings, { dispatch, getState }) => {
    const { user } = getState();
    const newUser = _.merge({}, user, { data: { settings } });

    dispatch(updateUserData(newUser));

    return newUser;
  }
);


export const logoutUser = () => async (dispatch, getState) => {
  const { user } = getState();

  if (!user.permissions || user.permissions.length === 0) {
    // is guest
    return null;
  }

  // history.push({
  //   pathname: '/',
  // });
  window.location.href = "/"

  localStorage.removeItem("user")
  
  dispatch(setInitialSettings());

  return dispatch(userLoggedOut());
};

export const updateUserData = (user) => async (dispatch, getState) => {
  if (!user.permissions || user.permissions.length === 0) {
    // is guest
    return;
  }

  jwtService
    .updateUserData(user)
    .then(() => {
      dispatch(showMessage({ message: 'User data saved with api' }));
    })
    .catch((error) => {
      dispatch(showMessage({ message: error.message }));
    });
};

const initialState = {
  id:"",
  name: '',
  email: '',
  status: "",
  role: "",
  permissions: [],
  photoURL: 'assets/images/avatars/user.png',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userLoggedOut: (state, action) => initialState,
    updatePermission: (state, action) => action.payload,
  },
  extraReducers: {
    [updateUserSettings.fulfilled]: (state, action) => action.payload,
    [setUser.fulfilled]: (state, action) => action.payload,
  },
});

export const { userLoggedOut, updatePermission } = userSlice.actions;

export const selectUser = ({ user }) => user;


export default userSlice.reducer;
