const navigationConfig = [
  {
    id: 'dashboards',
    title: 'Dashboards',
    type: 'group',
    icon: 'heroicons-outline:home',
    children: [
      {
        id: 'dashboards.subAdmin',
        title: 'Home',
        type: 'item',
        icon: 'heroicons-outline:home',
        url: '/dashboard/admin',
        auth: ["adminDashboard"],
      },
      {
        id: 'dashboards.admin',
        title: 'Home',
        type: 'item',
        icon: 'heroicons-outline:home',
        url: '/dashboard/subadmin',
        auth: ["subAdminDashboard"],
      },
      {
        id: 'dashboards.seo',
        title: 'Home',
        type: 'item',
        icon: 'heroicons-outline:home',
        url: '/dashboard/seo',
        auth: ["seoDashboard"],
      },
      {
        id: 'dashboards.designer',
        title: 'Home',
        type: 'item',
        icon: 'heroicons-outline:home',
        url: '/dashboard/designer',
        auth: ["designerDashboard"],
      },
    ],
  },
  {
    id: 'cms',
    title: 'CMS',
    type: 'group',
    icon: 'heroicons-outline:cube',
    auth: ["viewBlogCategory","viewBlogTag","viewBlog","viewFreeDesignCategory","viewFreeDesignTag","viewFreeDesign","viewGallery","viewFAQ","viewReview","viewBannerSlider","viewCustomer","viewCoupon","viewPricing","viewSubscriptionPlan","viewSubscription","viewInvoice "],
    children: [
      {
        id: 'cms.blogs',
        title: 'Blogs',
        type: 'collapse',
        icon: 'material-outline:edit_note',
        auth: ["viewBlogCategory", "viewBlogTag", "viewBlog"],
        children: [
          {
            id: 'blogs.all-blogs',
            title: 'All Blogs',
            type: 'item',
            url: '/blog/all',
            icon: 'material-outline:chrome_reader_mode',
            auth: ["viewBlog"],
          },
          {
            id: 'blogs.categories',
            title: 'Categories',
            type: 'item',
            url: '/blog/category',
            icon: 'material-outline:category',
            auth: ["viewBlogCategory"],
          },
          {
            id: 'blogs.tags',
            title: 'Tags',
            type: 'item',
            url: '/blog/tag',
            icon: 'material-outline:tag',
            auth: ["viewBlogTag"],
          },
          {
            id: 'blogs.author',
            title: 'Author',
            type: 'item',
            url: '/blog/author',
            icon: 'heroicons-outline:user-circle',
            // auth: ["viewBlogCategory"],
          },
          {
            id: 'blogs.media',
            title: 'Media',
            type: 'item',
            icon: 'heroicons-outline:photograph',
            url: '/blog/media',
            // auth: ["viewGallery"]
          },
        ],
      },
      {
        id: 'cms.free-design',
        title: 'Free Design',
        type: 'collapse',
        icon: 'heroicons-outline:collection',
        auth: ["viewFreeDesignCategory", "viewFreeDesignTag", "viewFreeDesign"],
        children: [
          {
            id: 'free-design.categories',
            title: 'Categories',
            type: 'item',
            url: '/free-design/category',
            icon: 'material-outline:category',
            auth: ["viewFreeDesignCategory"],
          },
          // {
          //   id: 'free-design.sub-categories',
          //   title: 'Sub Categories',
          //   type: 'item',
          //   url: '/free-design/sub-category',
          //   icon: 'material-outline:dashboard_customize',
          //   auth: ["viewFreeDesignSubCategory"]
          // },
          {
            id: 'free-design.tags',
            title: 'Tags',
            type: 'item',
            url: '/free-design/tag',
            icon: 'material-outline:tag',
            auth: ["viewFreeDesignTag"]
          },
          {
            id: 'free-design.all-freedesign',
            title: 'All Free Design',
            type: 'item',
            url: '/free-design/all',
            icon: 'material-outline:design_services',
            auth: ["viewFreeDesign"],
          },
        ],
      },
      {
        id: 'cms.gallery',
        title: 'Gallery',
        type: 'item',
        icon: 'heroicons-outline:photograph',
        url: '/gallery',
        auth: ["viewGallery"]
      },
      {
        id: 'cms.faqs',
        title: 'FAQs',
        type: 'item',
        icon: 'heroicons-outline:chat-alt',
        url: '/faq',
        auth: ["viewFAQ"]
      },
      {
        id: 'cms.reviews',
        title: 'Reviews',
        type: 'item',
        icon: 'heroicons-outline:clipboard-list',
        url: '/review',
        auth: ["viewReview"],
      },
      {
        id: 'cms.banner',
        title: 'Banner Slider',
        type: 'item',
        icon: 'material-outline:burst_mode',
        url: '/banner',
        auth: ["viewBannerSlider"]
      },
      {
        id: 'cms.customers',
        title: 'Customers',
        type: 'item',
        icon: 'heroicons-outline:user-group',
        url: '/customers',
        auth: ["viewCustomer"],
      },
      {
        id: 'cms.coupon',
        title: 'Coupon',
        type: 'item',
        icon: 'heroicons-outline:ticket',
        url: '/coupon',
        auth: ["viewCoupon"],
      },
      {
        id: 'cms.pricing',
        title: 'Pricing',
        type: 'item',
        icon: 'material-outline:local_atm',
        url: '/pricing',
        auth: ["viewPricing"],
      },
      {
        id: 'cms.subscription',
        title: 'Subscription Plan',
        type: 'item',
        icon: 'heroicons-outline:cube',
        url: '/subscription-plan',
        auth: ["viewSubscriptionPlan"],
      },
      {
        id: 'cms.subscriptionHistory',
        title: 'Subscription',
        type: 'item',
        icon: 'material-outline:history',
        url: '/subscription',
        auth: ["viewSubscription"],
      },
      {
        id: 'cms.invoice',
        title: 'Invoice',
        type: 'item',
        icon: 'heroicons-outline:clipboard-list',
        url: '/invoices',
        auth: ["viewInvoice"],
      },
    ],
  },
  {
    id: 'work',
    title: 'WORk',
    type: 'group',
    icon: 'heroicons-outline:home',
    auth: ["viewQuotation","viewOrder"],
    children: [
      {
        id: 'work.quotations',
        title: 'Quotations',
        type: 'item',
        icon: 'material-outline:dns',
        url: '/quotations',
        auth: ["viewQuotation"],
      },
      {
        id: 'work.orders',
        title: 'Orders',
        type: 'item',
        icon: 'material-outline:dns',
        url: '/orders',
        auth: ["viewOrder"]
      },
    ],
  },
  {
    id: 'hr',
    title: 'HR',
    type: 'group',
    icon: 'heroicons-outline:home',
    auth: ["viewJob"],
    children: [
      {
        id: 'hr.job',
        title: 'Job',
        type: 'item',
        icon: 'material-outline:post_add',
        url: '/job',
        auth: ["viewJob"]
      },
      {
        id: 'hr.employee',
        title: 'Employee',
        type: 'item',
        icon: 'heroicons-outline:users',
        url: '/employee',
        // auth: ["viewEmployee"]
      },
      {
        id: 'hr.department',
        title: 'Department',
        type: 'item',
        icon: 'material-outline:domain',
        url: '/department',
        auth: ["viewDepartment"]
      },
      {
        id: 'hr.designation',
        title: 'Designation',
        type: 'item',
        icon: 'material-outline:badge',
        url: '/designation',
        auth: ["viewDesignation"]
      },
      {
        id: 'hr.warning',
        title: 'Warning',
        type: 'item',
        icon: 'material-outline:warning_amber',
        url: '/warning',
        // auth: ["viewWarning"]
      },
      {
        id: 'hr.promotion',
        title: 'Promotion',
        type: 'item',
        icon: 'heroicons-outline:chart-square-bar',
        url: '/promotion',
        // auth: ["viewPromotion"]
      },
      {
        id: 'hr.leave',
        title: 'Leave',
        type: 'item',
        icon: 'heroicons-outline:calendar',
        url: '/leave',
        // auth: ["viewLeave"]
      },
    ],
  },
  {
    id: 'Setting',
    title: 'Setting',
    type: 'group',
    icon: 'heroicons-outline:home',
    // auth: ["viewActivityLog"],
    children: [
      {
        id: 'orderSettings',
        title: 'Order Settings',
        type: 'collapse',
        icon: 'material-outline:settings',
        auth: ["viewUnit","viewBorder","viewBacking","viewBranding","viewReturnTime","viewPatchType","viewColorModel","viewEmbroideryLocation","viewSocialMediaChannel","viewPatchEmbroidery","viewTrunaroundTime","viewShippingCompany","viewPaymentMethod"],
        children: [
          {
            id: 'orderSettings.unit',
            title: 'Units',
            type: 'item',
            // icon: 'material-outline:settings_applications',
            url: '/setting/units',
            auth: ["viewUnit"],
          },
          {
            id: 'orderSettings.borders',
            title: 'Borders',
            type: 'item',
            // icon: 'material-outline:settings_applications',
            url: '/setting/borders',
            auth: ["viewBorder"],
          },
          {
            id: 'orderSettings.backings',
            title: 'Backings',
            type: 'item',
            // icon: 'material-outline:settings_applications',
            url: '/setting/backings',
            auth: ["viewBacking"],
          },
          {
            id: 'orderSettings.brandings',
            title: 'Brandings',
            type: 'item',
            // icon: 'material-outline:settings_applications',
            url: '/setting/brandings',
            auth: ["viewBranding"],
          },
          {
            id: 'orderSettings.returnTime',
            title: 'Return Time',
            type: 'item',
            // icon: 'material-outline:settings_applications',
            url: '/setting/return-time',
            auth: ["viewReturnTime"],
          },
          {
            id: 'orderSettings.patchTypes',
            title: 'Patch Types',
            type: 'item',
            // icon: 'material-outline:settings_applications',
            url: '/setting/patch-types',
            auth: ["viewPatchType"],
          },
          {
            id: 'orderSettings.colorModels',
            title: 'Color Models',
            type: 'item',
            // icon: 'material-outline:settings_applications',
            url: '/setting/color-models',
            auth: ["viewColorModel"]
          },
          {
            id: 'orderSettings.embroideryLocations',
            title: 'Embroidery Locations',
            type: 'item',
            // icon: 'material-outline:settings_applications',
            url: '/setting/embroidery-locations',
            auth: ["viewEmbroideryLocation"],
          },
          {
            id: 'orderSettings.social',
            title: 'Social Media Channels',
            type: 'item',
            // icon: 'material-outline:settings_applications',
            url: '/setting/social-media-channels',
            auth: ["viewSocialMediaChannel"],
          },
          {
            id: 'orderSettings.patchEmbroideries',
            title: 'Patch Embroideries',
            type: 'item',
            // icon: 'material-outline:settings_applications',
            url: '/setting/patch-embroideries',
            auth: ["viewPatchEmbroidery"],
          },
          {
            id: 'orderSettings.turnaroundTimes',
            title: 'Turnaround Times',
            type: 'item',
            // icon: 'material-outline:settings_applications',
            url: '/setting/turnaround-times',
            auth: ["viewTrunaroundTime"],
          },
          {
            id: 'orderSettings.shipping',
            title: 'Shipping Company',
            type: 'item',
            // icon: 'material-outline:settings_applications',
            url: '/setting/shippings',
            auth: ["viewShippingCompany"],
          },
          {
            id: 'orderSettings.paymentMethod',
            title: 'Payment Method',
            type: 'item',
            // icon: 'heroicons-outline:credit-card',
            url: '/setting/payment-method',
            auth: ["viewPaymentMethod"],
          },
        ],
      },
      {
        id: 'basicSettings',
        title: 'Basic Settings',
        type: 'collapse',
        icon: 'material-outline:settings',
        auth: ["viewEmployeeType","viewSalaryType","viewWhitelistIP"],
        children: [
          {
            id: 'basicSettings.employee-type',
            title: 'Employee Type',
            type: 'item',
            // icon: 'material-outline:settings_applications',
            url: '/setting/employee-type',
            auth: ["viewEmployeeType"],
          },
          {
            id: 'basicSettings.salary-type',
            title: 'Salary Type',
            type: 'item',
            // icon: 'material-outline:settings_applications',
            url: '/setting/salary-type',
            auth: ["viewSalaryType"],
          },
          {
            id: 'basicSettings.leave-type',
            title: 'Leave Type',
            type: 'item',
            // icon: 'material-outline:settings_applications',
            url: '/setting/leave-type',
            // auth: ["viewEmployeeShift"],
          },
          {
            id: 'basicSettings.warning-type',
            title: 'Warning Type',
            type: 'item',
            // icon: 'material-outline:settings_applications',
            url: '/setting/warning-type',
            // auth: ["viewEmployeeShift"],
          },
          {
            id: 'basicSettings.employee-shift',
            title: 'Employee Shift',
            type: 'item',
            // icon: 'material-outline:settings_applications',
            url: '/setting/employee-shift',
            // auth: ["viewEmployeeShift"],
          },
          {
            id: 'basicSettings.whitelist-ip',
            title: 'Whitelist IPs',
            type: 'item',
            // icon: 'material-outline:settings_applications',
            url: '/setting/whitelist-ip',
            auth: ["viewWhitelistIP"],
          },
        ],
      },
      {
        id: 'setting.role',
        title: 'Role',
        type: 'item',
        icon: 'material-outline:supervised_user_circle',
        url: '/setting/roles',
        auth: ["viewRole"]
      },
      {
        id: 'setting.change-password',
        title: 'Change Password',
        type: 'item',
        icon: 'heroicons-outline:lock-closed',
        url: '/change-password',
      },
    ],
  },
  {
    id: 'logs',
    title: 'Logs',
    type: 'group',
    icon: 'heroicons-outline:home',
    auth: ["viewActivityLog"],
    children: [
      {
        id: 'logs.activity',
        title: 'Activity',
        type: 'item',
        icon: 'material-outline:history',
        url: '/logs/activity',
      },
    ],
  },

];

export default navigationConfig;
