import { combineReducers } from '@reduxjs/toolkit';
import navbar from './navbarSlice';
import message from './messageSlice';
import navigation from './navigationSlice';
import settings from './settingsSlice';

const fuseReducers = combineReducers({
  navigation,
  settings,
  navbar,
  message
});

export default fuseReducers;
