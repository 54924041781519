import { lazy } from 'react';
import { Navigate } from 'react-router-dom';


const FAQ = lazy(() => import('./faq/FAQ'));
const FAQs = lazy(() => import('./faqs/FAQs'));


const FAQAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'faq',
      element: <FAQs />,
      auth: ["viewFAQ"],
    },
    {
      path: 'faq/:faqId/*',
      element: <FAQ />,
      auth: ["addFAQ", "editFAQ"],
    },
    {
      path: 'faq',
      element: <Navigate to="faq" />,
    },
  ],
};

export default FAQAppConfig;
