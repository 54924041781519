
export const lightPaletteText = {
  primary: 'rgb(17, 24, 39)',
  secondary: 'rgb(107, 114, 128)',
  disabled: 'rgb(149, 156, 169)',
};

export const darkPaletteText = {
  primary: 'rgb(255,255,255)',
  secondary: 'rgb(148, 163, 184)',
  disabled: 'rgb(156, 163, 175)',
};

const themesConfig = {
  default: {
    "palette": {
      "mode": "light",
      "text": {
        "primary": "rgb(17, 24, 39)",
        "secondary": "rgb(107, 114, 128)",
        "disabled": "rgb(149, 156, 169)"
      },
      "common": {
        "black": "rgb(17, 24, 39)",
        "white": "rgb(255, 255, 255)"
      },
      "primary": {
        "light": "rgb(217, 242, 241)",
        "main": "#41c1ba",
        "dark": "rgb(52, 154, 148)",
        "contrastDefaultColor": "light",
        "contrastText": "rgba(0, 0, 0, 0.87)"
      },
      "secondary": {
        "light": "rgb(209, 217, 224)",
        "main": "#1b4465",
        "dark": "rgb(21, 54, 80)",
        "contrastText": "#fff"
      },
      "background": {
        "paper": "#FFFFFF",
        "default": "#f1f5f9"
      },
      "error": {
        "light": "#ffcdd2",
        "main": "#f44336",
        "dark": "#b71c1c"
      },
      "divider": "#e2e8f0"
    },
    "status": {
      "danger": "orange"
    }
  },
  defaultDark: {
    default: {
      "palette": {
        "mode": "light",
        "text": {
          "primary": "rgb(17, 24, 39)",
          "secondary": "rgb(107, 114, 128)",
          "disabled": "rgb(149, 156, 169)"
        },
        "common": {
          "black": "rgb(17, 24, 39)",
          "white": "rgb(255, 255, 255)"
        },
        "primary": {
          "light": "rgb(217, 242, 241)",
          "main": "#41c1ba",
          "dark": "rgb(52, 154, 148)",
          "contrastDefaultColor": "light",
          "contrastText": "rgba(0, 0, 0, 0.87)"
        },
        "secondary": {
          "light": "rgb(209, 217, 224)",
          "main": "#1b4465",
          "dark": "rgb(21, 54, 80)",
          "contrastText": "#fff"
        },
        "background": {
          "paper": "#FFFFFF",
          "default": "#f1f5f9"
        },
        "error": {
          "light": "#ffcdd2",
          "main": "#f44336",
          "dark": "#b71c1c"
        },
        "divider": "#e2e8f0"
      },
      "status": {
        "danger": "orange"
      }
    },
  },
  "navbar": {
    "palette": {
      "mode": "light",
      "text": {
        "primary": "rgb(17, 24, 39)",
        "secondary": "rgb(107, 114, 128)",
        "disabled": "rgb(149, 156, 169)"
      },
      "common": {
        "black": "rgb(17, 24, 39)",
        "white": "rgb(255, 255, 255)"
      },
      "primary": {
        "light": "rgb(217, 242, 241)",
        "main": "#41c1ba",
        "dark": "rgb(52, 154, 148)",
        "contrastDefaultColor": "light",
        "contrastText": "rgba(0, 0, 0, 0.87)"
      },
      "secondary": {
        "light": "rgb(209, 217, 224)",
        "main": "#1b4465",
        "dark": "rgb(21, 54, 80)",
        "contrastText": "#fff"
      },
      "background": {
        "paper": "#ffffff",
        "default": "#ffffff"
      },
      "error": {
        "light": "#ffcdd2",
        "main": "#f44336",
        "dark": "#b71c1c"
      },
      "divider": "rgba(241,245,249,.12)",
      "status": {
        "danger": "orange"
      }
    }
  },
  "toolbar": {
    "palette": {
      "mode": "light",
      "text": {
        "primary": "rgb(17, 24, 39)",
        "secondary": "rgb(107, 114, 128)",
        "disabled": "rgb(149, 156, 169)"
      },
      "common": {
        "black": "rgb(17, 24, 39)",
        "white": "rgb(255, 255, 255)"
      },
      "primary": {
        "light": "rgb(217, 242, 241)",
        "main": "#41c1ba",
        "dark": "rgb(52, 154, 148)",
        "contrastDefaultColor": "light",
        "contrastText": "rgba(0, 0, 0, 0.87)"
      },
      "secondary": {
        "light": "rgb(209, 217, 224)",
        "main": "#1b4465",
        "dark": "rgb(21, 54, 80)",
        "contrastText": "#fff"
      },
      "background": {
        "paper": "#FFFFFF",
        "default": "#f1f5f9"
      },
      "error": {
        "light": "#ffcdd2",
        "main": "#f44336",
        "dark": "#b71c1c"
      },
      "divider": "#e2e8f0"
    },
    "status": {
      "danger": "orange"
    }
  },
  "footer": {
      "palette": {
        "mode": "light",
        "text": {
          "primary": "rgb(17, 24, 39)",
          "secondary": "rgb(107, 114, 128)",
          "disabled": "rgb(149, 156, 169)"
        },
        "common": {
          "black": "rgb(17, 24, 39)",
          "white": "rgb(255, 255, 255)"
        },
        "primary": {
          "light": "rgb(217, 242, 241)",
          "main": "#41c1ba",
          "dark": "rgb(52, 154, 148)",
          "contrastDefaultColor": "light",
          "contrastText": "rgba(0, 0, 0, 0.87)"
        },
        "secondary": {
          "light": "rgb(209, 217, 224)",
          "main": "#1b4465",
          "dark": "rgb(21, 54, 80)",
          "contrastText": "#fff"
        },
        "background": {
          "paper": "#ffffff",
          "default": "#ffffff"
        },
        "error": {
          "light": "#ffcdd2",
          "main": "#f44336",
          "dark": "#b71c1c"
        },
        "divider": "rgba(241,245,249,.12)",
        "status": {
          "danger": "orange"
        }
      }
    },
  legacy: {
    "palette": {
      "mode": "light",
      "text": {
        "primary": "rgb(17, 24, 39)",
        "secondary": "rgb(107, 114, 128)",
        "disabled": "rgb(149, 156, 169)"
      },
      "common": {
        "black": "rgb(17, 24, 39)",
        "white": "rgb(255, 255, 255)"
      },
      "primary": {
        "light": "rgb(217, 242, 241)",
        "main": "#41c1ba",
        "dark": "rgb(52, 154, 148)",
        "contrastDefaultColor": "light",
        "contrastText": "rgba(0, 0, 0, 0.87)"
      },
      "secondary": {
        "light": "rgb(209, 217, 224)",
        "main": "#1b4465",
        "dark": "rgb(21, 54, 80)",
        "contrastText": "#fff"
      },
      "background": {
        "paper": "#FFFFFF",
        "default": "#f1f5f9"
      },
      "error": {
        "light": "#ffcdd2",
        "main": "#f44336",
        "dark": "#b71c1c"
      },
      "divider": "#e2e8f0"
    },
    status: {
      danger: 'orange',
    },
  },

};

export default themesConfig;
